@import 'src/variables';

.modal--delete-confirm {
  .modal-header {
    margin-bottom: 10px;
  }
  .list-items-card {
    margin-top: 10px;
  }
  .form-label {
    margin-right: auto;
  }
  .modal-footer {
    .form-label {
      > span {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .dns-setting-note-text {
    padding-top: 20px;
  }
  .dns-setting-alert-text {
    color: #d86b6b;
  }
  .dns-setting-multi-checkbox {
    padding-top: 10px;
  }
  .modal-footer__has-ebis-checkbox {
    justify-content: space-between;
    align-items: baseline;
  }

  .modal--delete-confirm--w650 .modal-content {
    width: 650px;
  }
}

.modal--delete-confirm__loading {
  .modal-header {
    margin-bottom: 30px;
  }
  .modal-footer {
    margin-top: 30px;
  }
}
